import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import PageTitle from 'components/display/PageTitle'
import TableSection from './components/Table/TableSection';
import Loading from 'components/display/Loading'
import contactApi from 'api/contactApi';

function ContactScreen() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        
        const getData = async () => {
            try {
                setLoading(true);
                const response = await contactApi.getList();
                if (response.data) {
                    setData(response.data);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        getData();

    }, []);

    

    return (
        <Box>
            <PageTitle text={'Danh sách liên hệ'} />
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Col>
                            <TableSection data={data} />
                        </Col>
                    </>
            }

        </Box>
    );
}

const Col = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
`

export default ContactScreen;