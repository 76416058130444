import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import Header from "./components/display/Header";
import Sidebar from "./components/display/Sidebar";
import LoginScreen from './screens/Login';
import ArticleScreen from './screens/Article';
import ArticleDetail from './screens/ArticleDetail';
import CategoryScreen from './screens/Category';
import ContactScreen from 'screens/ContactList';
import PartnerScreen from 'screens/Partner';
import PageNotFound from 'components/display/404';
import TagScreen from './screens/Tag';
import ListAdmin from './screens/ListAdmin';
import useToken from './utils/hooks/useToken';
import adminApi from 'api/adminApi';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux'
import { setStateUser } from 'redux/user/user.action'

const drawerWidth = 255;

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

function App() {
  const {token, setToken} = useToken();
  const [error , setError] = useState(false);
  const dispatch = useDispatch()
  const admin = useSelector((state) => state.userReducer.userStore.user);

  useEffect(() => {
    if (token) {
      const getDataAdmin = async () => {
        try {
          const res = await adminApi.getProfile();
          const data = res.data;
          dispatch(setStateUser(data));
        } catch (error) {}
      };
      getDataAdmin();
    }
  }, [token]);

  const handleLogout = () => {
    setToken(null);
  }

  const requestTimeoutToken = () => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        handleLogout();
      }
    }
  }

  requestTimeoutToken();

  return (
    <>
    {
      token ? 
      <>
        <BrowserRouter>
          <Header drawerWidth={drawerWidth} handleLogout={handleLogout} />
          <Sidebar drawerWidth={drawerWidth}/>
          <BoxContainer>
            <Routes>
              <Route path="/" element={<ArticleScreen />} />
              <Route path="/article-detail/:slug" element={<ArticleDetail />} />
              <Route path="/category" element={<CategoryScreen />} />
              <Route path="/tag" element={<TagScreen />} />
              {admin.isRootAdmin && (
                <>
                  <Route path="/contact-list" element={<ContactScreen />} />
                  <Route path="/partner" element={<PartnerScreen />} />
                  <Route path="/list-admin" element={<ListAdmin />} />
                </>
              )}

            <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BoxContainer>
        </BrowserRouter>
      </>
      :
      <LoginScreen setToken={setToken} error={error}/>
    }
    </>
    
  );
}

const BoxContainer = styled(Box)`
  padding-left: ${drawerWidth}px;
  padding-top: 64px;
`;

export default App;
