import axiosClient from "./axiosClient";

class ContactApi {
  getList = () => {
    const url = '/contact-form/all';
    return axiosClient.get(url);
  };
}

const contactApi = new ContactApi();
export default contactApi;