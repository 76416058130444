import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import categoryApi from '../../../../api/categoryApi';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import styled from 'styled-components';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({createSuccess, open, handleClose, data, token, success, setSuccess, error, setError }) {
  const [cateItem, setCate] = React.useState({
    name: '',
    logo: '',
  });
  const [images, setThumb] = useState([]);

  const handleInputChange = (event) => {
    setCate({
      ...cateItem,
      name: event.target.value
    });
  }

  const onChangeImageThumb = async (imageList) => {
    if (imageList[0]) {
      setThumb(imageList);
      setCate({
        ...cateItem,
        logo: imageList[0].file
      });
    }
    
  };

  useEffect(() => {
    if (data) {
      setCate({
        ...cateItem,
        name: data?.name
      });
    } else {
      setCate({
        ...cateItem,
        name: ''
      })
    }
      
    setThumb([]);
  }, [open]);

  const handleCreate = () => {
    setError('');
    if (!cateItem.name || !cateItem.logo) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    } else {
      createCate();
    }
  };

  const handleUpdate = () => {
    setError('');
    if (!cateItem.name) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    } else {

      updateCate();
    }
  }

  const createCate = async () => {
    try {
      // const response = await categoryApi.create(param);
      const formData = new FormData();
      formData.append('name', cateItem.name);
      formData.append('logo', cateItem.logo);
      const response = await axios.post(`${process.env.REACT_APP_URL_API}/category`, formData, { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } });
      if (response) {
        setSuccess('Tạo danh mục thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  const updateCate = async () => {
    try {
      const formData = new FormData();
      formData.append('categoryId', data?.id);
      formData.append('name', cateItem.name);
      if (cateItem.logo) formData.append('logo', cateItem.logo);
      const response = await axios.patch(`${process.env.REACT_APP_URL_API}/category`, formData, { headers: { "Authorization": `Bearer ${token}`, 'content-type': 'multipart/form-data' } });
      if (response) {
        setSuccess('Cập nhật danh mục thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {!success && (
          <>
          <Box sx={{marginBottom: '15px', fontSize: '24px', fontWeight: '500'}}>
            { data ? 'Cập nhật danh mục' : 'Tạo danh mục mới' }
          </Box>
          <TextField
            fullWidth
            required
            name="cate"
            value={cateItem.name}
            onChange={handleInputChange}
            label="Tên danh mục"
            variant="outlined"
          />
          <Box sx={{marginTop: '15px'}}>
            {(data?.image && !images.length) && (
              <div className="image-item">
                <img src={data?.image} alt="" width="100" />
            </div>
            )}
          <ImageUploading
              multiple
              value={images}
              onChange={onChangeImageThumb}
              maxNumber={1}
              dataURLKey="data_url"
          >
              {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
              }) => (
                  <div className="upload__image-wrapper">
                      {!imageList.length &&
                          <ButtonCustomUpload
                              style={isDragging ? { color: '#1f89e5' } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                          >
                              <InsertPhotoIcon />
                          </ButtonCustomUpload>
                      }
                      {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                                <img src={image['data_url']} alt="" width="100" />
                                <div className="image-item__btn-wrapper">
                                  <ButtonCustomUpload onClick={() => onImageUpdate(index)}>Đổi</ButtonCustomUpload>
                                </div>
                            </div>
                        ))}
                      
                  </div>
              )}
          </ImageUploading>
          </Box>
          {error && <Box sx={{marginTop: '15px'}}><Alert severity="error">{error}</Alert></Box>}
          <Box sx={{marginTop: '15px'}}>
            {
              data ?
                <Button variant="outlined" onClick={handleUpdate}>Cập nhật danh mục</Button>
              :
                <Button variant="outlined" onClick={handleCreate}>Tạo danh mục</Button>
            }
          </Box>
          </>
          )}
          {success && <Box sx={{marginTop: '15px'}}>
            <Alert severity="success">{success}</Alert>
            <Box sx={{marginTop: '15px'}}>
              <Button variant="outlined" onClick={handleClose}>Close</Button>
            </Box>
          </Box>}
        </Box>
      </Modal>
    </div>
  );
}

const ButtonCustomUpload = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  color: #58667E;
  font-weight: bold;
  padding: 5px 10px;
  margin-right: 5px;
`;