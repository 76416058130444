import { combineReducers } from "redux";
import * as constants from "./user.constant";

const initialState = {
  user: false,
};

function userStore(state = initialState, action) {
  switch (action.type) {
    case constants.SET_USER_STATE:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}

export default combineReducers({ userStore });