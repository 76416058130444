import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import PageTitle from '../../components/display/PageTitle'
import TableSection from './components/Table/TableSection';
import axios from "axios";
import Loading from '../../components/display/Loading'
import categoryApi from '../../api/categoryApi';
import useToken from '../../utils/hooks/useToken';

function CategoryScreen(props) {
    const { token, setToken } = useToken();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const resCategories = await axios.get(`${process.env.REACT_APP_URL_API}/category/all`, { headers: { "Authorization": `Bearer ${token}` } });
            if (resCategories.data) {
                const items = resCategories.data.data;
                setData(items);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Box>
            <PageTitle text={'Danh mục bài viết'} />
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Col>
                            <TableSection data={data} />
                        </Col>
                    </>
            }

        </Box>
    );
}
const Col = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
`

export default CategoryScreen;