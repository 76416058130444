import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
// import tagApi from '../../../../api/tagApi';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({createSuccess, open, handleClose, data, token, success, setSuccess, error, setError}) {
  const [tag, setTag] = React.useState('');

  const handleInputChange = (event) => {
    setTag(event.target.value);
  }

  useEffect(() => {
    setTag(data?.name);
  }, [open]);

  const handleCreate = () => {
    setError('');
    if (!tag) {
      setError('Vui lòng nhập tag!');
      return;
    } else {
      createtag();
    }
  };

  const handleUpdate = () => {
    setError('');
    if (!tag) {
      setError('Vui lòng nhập tag!');
      return;
    } else {
      updateTag();
    }
  }

  const createtag = async () => {
    try {
      const param = {
        name: tag
      };
      const response = await axios.post(`${process.env.REACT_APP_URL_API}/tag`, param, { headers: { "Authorization": `Bearer ${token}` } });
      if (response) {
        setSuccess('Tạo tag thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  const updateTag = async () => {
    try {
      const param = {
        name: tag,
        tagId: data.id
      };
      // const response = await tagApi.create(param);
      const response = await axios.patch(`${process.env.REACT_APP_URL_API}/tag`, param, { headers: { "Authorization": `Bearer ${token}` } });
      if (response) {
        setSuccess('Cập nhật tag thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {!success && (
          <>
          <Box sx={{marginBottom: '15px', fontSize: '24px', fontWeight: '500'}}>
            
            { data ? 'Cập nhật tag' : 'Tạo tag mới' }
          </Box>
          <TextField
            fullWidth
            required
            name="tag"
            value={tag}
            onChange={handleInputChange}
            label="Tên tag"
            variant="outlined"
          />
          {error && <Box sx={{marginTop: '15px'}}><Alert severity="error">{error}</Alert></Box>}
          <Box sx={{marginTop: '15px'}}>
            {
              data ?
                <Button variant="outlined" onClick={handleUpdate}>Cập nhật tag</Button>
              :
                <Button variant="outlined" onClick={handleCreate}>Tạo tag</Button>
            }
          </Box>
          </>
          )}
          {success && <Box sx={{marginTop: '15px'}}>
            <Alert severity="success">{success}</Alert>
            <Box sx={{marginTop: '15px'}}>
              <Button variant="outlined" onClick={handleClose}>Close</Button>
            </Box>
          </Box>}
        </Box>
      </Modal>
    </div>
  );
}