import React , {useState} from 'react';
import { Container, Box, Button, TextField, Typography, Fade, CircularProgress, Alert } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/logo/LegendGuild-18.svg'
import authApi from '../../api/authApi';
import styled from 'styled-components';
import axios from "axios";

function LoginScreen({setToken}) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  }

  const handleLogin = async () => {
    setLoading(true);
    setError(false);
    try {
      const params = {...values};
      const response = await axios.post(`${process.env.REACT_APP_URL_API}/admin/sign-in`, params);
      if(response && response.data) {
        setToken(response.data.data.accessToken);
        // history.push('/')
      }
      setLoading(false);
    } catch (error) { setError(true); setLoading(false); }
  };


  return (
    <Container>
      <WrapperLogin>
        <Box width={250} sx={{ margin: '0 auto 20px'}}>
          <Logo/>
        </Box>
        <Typography variant='h2'>Login</Typography>
        <Box sx={{ marginTop: '10px', maxWidth: '90%' }}>
          <FieldBox>
            <Label>Địa chỉ email</Label>
              <TextField
                fullWidth
                name="email"
                value={values.email}
                onChange={handleInputChange}
                placeholder="your-email@gmail.com"
                variant="outlined"
              />
          </FieldBox>
          <FieldBox>
            <Label>Mật khẩu</Label>
            <TextField
              fullWidth
              name="password"
              type="password"
              value={values.password}
              onChange={handleInputChange}
              placeholder="******"
              variant="outlined"
            />
          </FieldBox>
          {error && (
            <FieldBox>
              <Alert variant="outlined" severity="error">
                Email hoặc mật khẩu không đúng
              </Alert>
            </FieldBox>
          )}
          <FieldBox>
            <LoginButton 
              variant="contained"
              onClick={() => handleLogin(values)}
              className={loading ? 'loading-btn' : ''}
            >
              <Fade
                in={loading}
                unmountOnExit
              >
                <CircularProgress sx={{ color: '#ffffff', height: '20px!important', width: '20px!important',  }} />
              </Fade>
              Đăng nhập
            </LoginButton>
          </FieldBox>
        </Box>
      </WrapperLogin>
    </Container>
  )
}

const WrapperLogin = styled(Box)`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FieldBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
`;

const Label = styled(Box)`
  color: #58667E;
  font-weight: 500;
`;

const LoginButton = styled(Button)`
  background-color: rgba(68,109,255, 1);
  box-shadow: none;
  padding: 8px 40px;
  margin: 10px auto 0;
  &:hover {
    background-color: rgba(68,109,255, .8);
    box-shadow: none;
  }
  &.loading-btn {
    cursor: progress;
    pointer-events: none;
  }
`;

export default LoginScreen;