import React, { useEffect, useState } from "react";
import { Box, Button, TextField, FormControl } from '@mui/material'
import { useNavigate } from "react-router-dom";
import PageTitle from '../../components/display/PageTitle'
import TableSection from './components/Table/TableSection';
import axios from "axios";
import Loading from '../../components/display/Loading'
import useToken from '../../utils/hooks/useToken';
import { styled as muiStyled } from '@mui/material/styles';
import styled from '@emotion/styled'
import {ReactComponent as SearchIcon} from 'assets/icons/search.svg'
import { useSelector } from 'react-redux'

function ArticleScreen(props) {
    const [data, setData] = useState();
    const [dataSearch, setDataSearch] = useState();
    const [searchIpt, setSearchIpt] = useState('');
    const [loading, setLoading] = useState(true);
    const { token, setToken } = useToken();
    const navigate = useNavigate();
    const [paginate, setPaginate] = useState();

    const admin = useSelector((state) => state.userReducer.userStore.user);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        if (admin) {
            getData(page, limit, '-updatedAt');
        }
    }, [admin]);

    const getData = async (page, limit, sortBy) => {
        try {
            setLoading(true);
            const param = {
                page: page,
                limit: limit,
                sortBy: '-updatedAt',
                keyword: searchIpt ? searchIpt : "",
                author: admin._id
            }

            const res = await axios.get(`${process.env.REACT_APP_URL_API}/article/all`, { params: param, headers: { "Authorization": `Bearer ${token}` } });
            if (res.data) {
                const items = res.data.data;
                setData(items);
                setPaginate(res.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setSearchIpt(event.target.value);
    };

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            getData(page, limit, '-updatedAt')
        }
    };

    const handleCreateArticle = () => {
        navigate("/article-detail/new");
    }

    return (
        <Box>
            <PageTitle text={'Danh sách bài viết'} />
            {
                loading ?
                    <Loading />
                    :
                    <>
                        <Col>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Flex justifyContent="space-between">
                                <FormControl sx={{
                                    flexDirection: 'row',
                                    gap: '20px',
                                    flexWrap: 'wrap',
                                }}>
                                    <SearchField 
                                        placeholder="Tên bài viết" 
                                        value={searchIpt}
                                        onChange={handleChange}
                                        onKeyDown={handleSearch}
                                        
                                        InputProps={{
                                            startAdornment: <SearchIcon/>,
                                        }}
                                    />
                                </FormControl>
                                <FormControl sx={{
                                    flexDirection: 'row',
                                    gap: '20px',
                                    alignItems: 'center',
                                    '& .MuiOutlinedInput-root': {
                                        background: '#EFF2F5',
                                        border: '1px solid #EAEAEA',
                                        borderRadius: '8px',                    
                                    '& fieldset': {
                                        border: 'none'
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    
                                    },
                                }}>
                                </FormControl>
                            </Flex>
                                <Button variant="outlined" onClick={handleCreateArticle}>Tạo bài viết mới</Button>
                            </Box>
                            <TableSection data={data} paginate={paginate} getData={getData} />
                        </Col>
                    </>
            }

        </Box>
    );
}
const Col = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
`
const Flex = styled(Box)`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: calc(100% - 200px);
`
const SearchField = muiStyled(TextField)({
    width: '283px',
    borderRadius: '8px',
    border: '1px solid #EAEAEA',
    background: '#EFF2F5',
    '&.MuiFormControl-root.MuiTextField-root': {
        width: '283px',
    },

    '& svg': {
        marginRight: '10px'
    },

    '& input': {
        paddingTop: '11px',
        paddingBottom: '13px',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: '500',
        color: '#58667E',
    }
});

export default ArticleScreen;