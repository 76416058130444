import { createTheme } from '@mui/material/styles';

export const themeCustomApp = createTheme({
  typography: {
    fontFamily: [
      'Poppins Regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Poppins Bold',
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Poppins Bold',
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: 'Poppins Bold',
      fontSize: '1.8rem',
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: 'Poppins Bold',
      fontSize: '1.6rem',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'Poppins Bold',
      fontSize: '1.4rem',
      color: '#ffffff',
    },
    h6: {
      fontFamily: 'Poppins Bold',
      fontSize: '1.2rem',
    },
  },
});