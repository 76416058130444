import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination, Box, Button, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { Route, Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as MoreIcon } from '../../../../icon/more.svg'
import { ReactComponent as EditIcon } from '../../../../icon/edit.svg'
import { ReactComponent as TrashIcon } from '../../../../icon/trash.svg'

function createData(index, email, firstName, lastName, message, id) {
  return { index, email, firstName, lastName, message, id };
}

const TableSection = ({ data }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [row, setRow] = useState([]);

  useEffect(() => {
    setRow([]);
    data?.map((item, index) => {
      setRow(data => [...data, createData(index + 1, item?.email, item?.firstName, item?.lastName, item?.message, item?._id)])
    })
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleContact = (item) => {
    console.log('item==>', item);
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      maxWidth: 155,
      width: '155px',
      border: 'none',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06)',
      borderRadius: '8px',
      padding: '16px 0',
    },
  }));

  const addButton = {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#111827",
    textTransform: "none",
    width: '100%',
    padding: '12px',
    justifyContent: 'flex-start',

    "svg": {
      marginRight: "10px"
    }
  }

  return (
    <>
      <Paper sx={{ position: "relative", }}>
        <TableContainer sx={{ border: "1px solid rgba(224, 224, 224, 1)", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <Table sx={{ minWidth: 700, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflow: 'hidden', }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: '#F7F8FA' }}>
              <StyledTableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Message</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {row.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                <StyledTableRow key={i} sx={{ textDecoration: "none" }}>
                  <StyledTableCell scope="row">
                    {row.index}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    <a href={`mailto:${row.email}`}>{row.email}</a>
                  </StyledTableCell>
                  <StyledTableCell>
                    {`${row.firstName} ${row.lastName}`}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.message}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Hiển thị"
          component="div"
          count={row.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="pagination-bottom"
        />
      </Paper>
    </>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#11142D',
    paddingTop: '12px',
    paddingBottom: '12px'
  },

  [`&.${tableCellClasses.body}`]: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#58667E',
    paddingTop: '12px',
    paddingBottom: '12px',
    
    '& a': {
      color: '#58667E',
    }
  },
}));

const Img = styled.img`
  max-width: 100px;
  width: 100%;
  height: auto;
`

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  '&:nth-of-type(odd)': {

  },
  // hide last border
  '&:last-child td, &:last-child th': {

  },
}));

export default TableSection
