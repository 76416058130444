import axios from 'axios';

function ImageUploadAdapterPlugin(editor) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
    return new ImageUploadAdapter(loader)
  }
}

class ImageUploadAdapter {

  constructor(loader) {
    this.loader = loader
  }

  async upload() {
    return this.loader.file.then((file) => {
      const tokenString = localStorage.getItem('token');
      const token = JSON.parse(tokenString);
      const data = new FormData();
      data.append("file", file);
      const genericError = `Couldn't upload file: ${file.name}.`;

      return axios({
        data,
        method: "POST",
        url: `${process.env.REACT_APP_URL_API}/image/article`,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${token}`
        },
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total;
          this.loader.uploaded = progressEvent.loaded;
        },
      })
      .then(({ data }) => ({ default: data.url }))
      .catch(({ error }) => Promise.reject(error?.message ?? genericError))
    })
  }

  abort() {
    return Promise.reject()
  }
}

export default ImageUploadAdapterPlugin;