import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePagination, Pagination, Button, Tooltip, Typography, tooltipClasses } from '@mui/material'
import { Route, Link, useRouteMatch, useNavigate } from "react-router-dom";


function createData(index, title, author, categories, tags, date, thumbnail, id, slug) {
  let d = new Date(date);
  date = d.getDate() + "/"+ parseInt(d.getMonth()+1) +"/"+d.getFullYear();
  return { index, title, author, categories, tags, date, thumbnail, id, slug };
}

const TableSection = ({ data, paginate, getData }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [row, setRow] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setRow([]);
    data?.map((item, index) => {
      setRow(data => [...data, createData(index + 1, item?.title, item?.author?.name, item?.categories, item?.tags, item?.updatedAt, item?.thumbnail, item?._id, item?.slug)])
    })
  }, [data]);

  const handleChangePage = (event, page) => {
    getData(page, 10);
  };

  const handleClickItem = (item) => {
    navigate(`/article-detail/${item.slug}`);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
    <Paper sx={{ position: "relative", }}>
      <TableContainer sx={{ border: "1px solid rgba(224, 224, 224, 1)", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
        <Table sx={{ minWidth: 700, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflow: 'hidden', }} aria-label="customized table">
          <TableHead sx={{ backgroundColor: '#F7F8FA' }}>
            <StyledTableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Bài viết</StyledTableCell>
              <StyledTableCell>Tác giả</StyledTableCell>
              <StyledTableCell>Categories</StyledTableCell>
              <StyledTableCell>Tags</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Thumbnail</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {row.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
              <StyledTableRow key={i} onClick={() => handleClickItem(row)}
                              sx={{ textDecoration: "none" }}>
                <StyledTableCell scope="row">
                  {row.index}
                </StyledTableCell>
                <StyledTableCell scope="row">
                  {row.title}
                </StyledTableCell>
                <StyledTableCell>
                  {row.author}
                </StyledTableCell>
                <StyledTableCell>
                  {row.categories.map((item, index) => (
                    item.name + (index + 1 >= row.categories.length ? '' : ', ')
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  {row.tags.map((item, index) => (
                    item.name + (index + 1 >= row.tags.length ? '' : ', ')
                  ))}
                </StyledTableCell>
                <StyledTableCell>
                  Chỉnh sửa cuối cùng vào lúc: 
                  {row.date}
                </StyledTableCell>
                <StyledTableCell>
                  <Img src={row.thumbnail} alt={row.title} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    <Pagination sx={{marginTop: '10px', marginBottom: '40px'}} count={paginate.totalPages} defaultPage={paginate.page} color="primary" onChange={handleChangePage} />
    </>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#11142D',
    paddingTop: '12px',
    paddingBottom: '12px'
  },

  [`&.${tableCellClasses.body}`]: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#58667E',
    paddingTop: '12px',
    paddingBottom: '12px'
  },
}));

const Img = styled.img`
  max-width: 100px;
  width: 100%;
  height: auto;
`

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:nth-of-type(odd)': {

  },
  // hide last border
  '&:last-child td, &:last-child th': {

  },
}));

export default TableSection
