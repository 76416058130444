import React from 'react';
import { AppBar, IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux'

function Header({ drawerWidth, handleLogout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const admin = useSelector((state) => state.userReducer.userStore.user);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        height: '64px',
        color: 'inherit',
        zIndex: 10,
      }}
    >
      <div>
        {admin.name}
        <IconButton
          size="large"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            '& .MuiPopover-paper': {
              border: '1px solid #333',
              borderRadius: '8px'
            }
          }}
        >
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem onClick={() => {
            handleClose()
            handleLogout()
          }}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </AppBar>
  );
}

export default Header;