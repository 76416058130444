import ImageUploadAdapterPlugin from '../../utils/helpers/imageUploadEditor';
// import Font from '@ckeditor/ckeditor5-font/src/font';

export const configCkeditor = {
  // plugins: [ Font ],
  extraPlugins: [ ImageUploadAdapterPlugin ],
  // toolbar: {
  //   items: [
  //     'heading',
  //     '|',
  //     'bold',
  //     'italic',
  //     'link',
  //     'bulletedList',
  //     'numberedList',
  //     '|',
  //     'blockQuote',
  //     'insertTable',
  //     '|',
  //     'imageUpload',
  //     'undo',
  //     'redo'
  //   ]
  // },
  // table: {
  //   contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
  // }
};