import ArticleIcon from '@mui/icons-material/Article';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

export const projectNav = [
  {
    label: 'Bài viết',
    href: '/',
    icon: ArticleIcon
  },
  {
    label: 'Danh mục bài viết',
    href: '/category',
    icon: SummarizeIcon
  },
  {
    label: 'Tags',
    href: '/tag',
    icon: LocalOfferIcon
  },
];

export const AdminList = [
  {
    label: 'Danh sách đối tác',
    href: '/partner',
    icon: FeaturedPlayListIcon
  },
  {
    label: 'Danh sách liên hệ',
    href: '/contact-list',
    icon: ContactPageIcon
  },
  {
    label: 'Danh sách admin',
    href: '/list-admin',
    icon: SupervisorAccountIcon
  }
]