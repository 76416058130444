import axiosClient from "./axiosClient";

class ArticleApi {
  getAll = (params) => {
    const url = '/article/all';
    return axiosClient.get(url, { params });
  };
  getDetail = (params) => {
    const url = '/article';
    return axiosClient.get(url, { params });
  };
  updateArticle = (params) => {
    const url = '/article';
    return axiosClient.patch(url, {params});
  }
  createThumbnail = (params) => {
    const url = '/image/article';
    return axiosClient.post(url, {params});
  }
  deleteThumbnail = (params) => {
    const url = '/image/article';
    return axiosClient.delete(url, {params});
  }
}

const articletApi = new ArticleApi();
export default articletApi;