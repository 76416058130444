import PropTypes from 'prop-types';
import styled from 'styled-components';

function LoaderEllipsis({ width, height, color }) {
  return (
    <LoaderEllipsisWrapper width={width} height={height} color={color}><div></div><div></div><div></div><div></div></LoaderEllipsisWrapper>
  )
}

LoaderEllipsis.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}

LoaderEllipsis.defaultProps = {
  width: 72,
  height: 36,
  color: '#1976d2',
};

const LoaderEllipsisWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  div {
    position: absolute;
    top: ${props => (props.height - 13) / 2}px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${props => props.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export default LoaderEllipsis;