import axiosClient from "./axiosClient";

class AuthApi {
  login = (params) => {
    const url = '/admin/sign-in';
    return axiosClient.post(url, params);
  };
}

const authApi = new AuthApi();
export default authApi;