import PageTitle from 'components/display/PageTitle'
import { Box } from '@mui/material'
import { Link } from "react-router-dom"

function PageNotFound() {
  return (
      <Box>
          <PageTitle text={'Trang ko được tìm thấy'} />
          <Box sx={{ padding: '20px 15px' }}>
              <Link to={'/'} >Trở về trang chủ</Link>
          </Box>
      </Box>
  );
}

export default PageNotFound