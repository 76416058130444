import axiosClient from "./axiosClient";

class TagApi {
  getAll = (params) => {
    const url = '/tag/all';
    return axiosClient.get(url, { params });
  };
}

const tagApi = new TagApi();
export default tagApi;