import React, { useState, useEffect } from 'react';
import { Grid, Box, Chip, Button, Typography, TextField, TextareaAutosize, Autocomplete, Alert, AlertTitle, Checkbox } from '@mui/material';
import ImageUploading from 'react-images-uploading';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { configCkeditor } from '../../constants/CkEditor';
import { FieldArticle } from '../../constants/Article';
import LoaderEllipsis from '../../components/display/Loader/ellipsis';
import styled from 'styled-components';
import { decode } from 'html-entities';
import axios from "axios";
import useToken from '../../utils/hooks/useToken';
import categoryApi from '../../api/categoryApi'
import articletApi from 'api/articleApi'
import tagApi from '../../api/tagApi'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Loading from '../../components/display/Loading'
import { useNavigate, useParams } from "react-router-dom";

function ArticleDetail() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    // const [loading, setLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [images, setThumb] = useState([]);
    const [article, setArticle] = useState(initialArticle);
    const [validator, setValidator] = useState({});
    const { token, setToken } = useToken();
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [thumbnailObject, setThumbnailObject] = useState();
    const [isCreate, setIsCreate] = useState(true);
    let navigate = useNavigate();

    let { slug } = useParams();

    const validate = () => {
        setValidator({});
        const requiredField = ['title', 'thumbnail', 'content'];
        const validatorTpm = {};
        requiredField.forEach(field => {
            if (!article[field]) validatorTpm[field] = `${FieldArticle[field]} không được để trống`;
        });
        setValidator(validatorTpm);
    };

    useEffect(() => {
        initData();
    }, []);

    const initData = async () => {
        try {
            setLoading(true);

            const resCategories = await categoryApi.getAll();
            setCategories(resCategories.data);

            const resTags = await tagApi.getAll();
            setTags(resTags.data);

            // const dataString = localStorage.getItem('PostArticle');

            // Run if edit article
            // if (dataString) {
            //     setIsCreate(false);
            //     const postArticle = JSON.parse(dataString);
            //     const param = {
            //         articleId: postArticle.id,
            //     }
            //     const res = await axios.get(`${process.env.REACT_APP_URL_API}/article`, { params: param, headers: { "Authorization": `Bearer ${token}` } });
            //     const data = res.data.data;

            //     setThumb([{ data_url: data?.thumbnail }])

            //     Object.keys(data).forEach(function (key) {
            //         if (key === 'title' || key === 'shortDescription' || key === 'categories' || key === 'tags') {
            //             setArticle(prevState => ({
            //                 ...prevState,
            //                 [key]: data[key]
            //             }))
            //         }
            //         if (key === 'content') {
            //             setArticle(prevState => ({
            //                 ...prevState,
            //                 [key]: decode(data[key])
            //             }))
            //         }
            //         if (key === '_id') {
            //             setArticle(prevState => ({
            //                 ...prevState,
            //                 'articleId': data[key]
            //             }))
            //         }
            //     });
            // }
            // // Run if create article
            // else {
            //     setIsCreate(true);
            // }

            if (slug !== 'new') {
                setIsCreate(false);
                const params = { 
                    articleSlug: slug
                }
                const res = await articletApi.getDetail(params);
                const dataArticle = res.data;
                setArticle(dataArticle)
            } else {
                setIsCreate(true);
            }
            setLoading(false);

        } catch (error) { 
            setLoading(false);
        }
    }

    const onChangeImageThumb = async (imageList) => {
        // Upload image to server
        // return image URL

        // if (thumbnailObject && thumbnailObject._id) {
        //     const res = await axios.delete(`${process.env.REACT_APP_URL_API}/image/article`, { data: thumbnailObject, headers: { "Authorization": `Bearer ${token}` } });
        // }
        const form = new FormData();
        form.append("file", imageList[0].file);
        const thumbnailData = await axios.post(`${process.env.REACT_APP_URL_API}/image/article`, form, { headers: { "Authorization": `Bearer ${token}`, "Content-Type": "multipart/form-data" } });
        setThumbnailObject(thumbnailData.data);

        setThumb(imageList);
        setArticle({ ...article, thumbnail: imageList[0].file });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setArticle({ ...article, [name]: value });
    };

    const handleCategoriesChange = (event, newValue) => {
        setArticle({
            ...article,
            ['categories']: newValue,
        });
    };

    const handleTagsChange = (event, newValue) => {
        setArticle({
            ...article,
            ['tags']: newValue,
        });
    };

    const handleCreateArticle = async () => {
        validate();
        try {
            setLoading(true);
            const dataPatch = article;

            // change code
            let categorIds = []
            dataPatch.categories.map((item, index) => {
                categorIds.push(item._id);
            })
            dataPatch.categories = categorIds;

            let tagIds = []
            dataPatch.tags.map((item, index) => {
                tagIds.push(item._id);
            })
            dataPatch.tags = tagIds;

            dataPatch.thumbnail = thumbnailObject?.url;
            // END change code
            const res = await axios.post(`${process.env.REACT_APP_URL_API}/article`, dataPatch, { headers: {"Authorization" : `Bearer ${token}`} });

            if (res.data) {
                setLoading(false);
                navigate('/');
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const handleUpdateArticle = async () => {
        try {
            setLoading(true);
            const dataPatch = article;
            dataPatch.articleId = dataPatch._id;

            // change code
            let categorIds = []
            dataPatch.categories.map((item, index) => {
                categorIds.push(item._id);
            })
            dataPatch.categories = categorIds;

            let tagIds = []
            dataPatch.tags.map((item, index) => {
                tagIds.push(item._id);
            })
            dataPatch.tags = tagIds;

            dataPatch.thumbnail = thumbnailObject?.url;
            // END change code

            // Tạm thời sử dụng patch riêng
            const res = await axios.patch(`${process.env.REACT_APP_URL_API}/article`, dataPatch, { headers: { "Authorization": `Bearer ${token}` } });
            if (res.data)
                setLoading(false);
        } catch (error) {
            setLoading(false);
        }

    }

    const handleDeleteArticle = async () => {
        try {
            console.log('article===>', article);
            setLoading(true);
            const param = {
                "articleId": article._id
            }
            const res = await axios.delete(`${process.env.REACT_APP_URL_API}/article`, { data: param, headers: { "Authorization": `Bearer ${token}` } });
            if (res.data) {
                navigate('/');
                setLoading(false);
            }           
        } catch (error) {
            setLoading(false);
        }
    }


    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <Box sx={{ padding: '20px 15px' }}>
                        <Typography variant='h1'>{isCreate ? 'Tạo bài viết mới' : 'Cập nhật bài viết'}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                {Object.keys(validator).length !== 0 && (
                                    <Alert variant="outlined" severity="error" sx={{ marginTop: '10px' }}>
                                        <AlertTitle>Error</AlertTitle>
                                        <Box sx={{ whiteSpace: 'pre-wrap' }}>{Object.values(validator).join('\n')}</Box>
                                    </Alert>
                                )}
                                <FieldBox>
                                    <TextField
                                        fullWidth
                                        required
                                        name="title"
                                        value={article.title}
                                        onChange={handleInputChange}
                                        placeholder={FieldArticle.title}
                                        variant="outlined"
                                    />
                                </FieldBox>
                                <Box sx={{ margin: '15px 0' }}>
                                    <div id="toolbar-container"></div>
                                    <Box sx={{ '& .ck-content.ck-editor__editable' : { border: '1px solid rgba(0, 0, 0, 0.3)' } }}>
                                    <CKEditor
                                        editor={DecoupledEditor}
                                        config={configCkeditor}
                                        onReady={ editor => {
                                            // Add the toolbar to the container
                                            const toolbarContainer = document.querySelector( '#toolbar-container' );
                                            if (toolbarContainer) toolbarContainer.appendChild( editor.ui.view.toolbar.element );
                                    
                                            window.editor = editor;
                                                // You can store the "editor" and use when it is needed.
                                                console.log( 'Editor is ready to use!', editor );
                                        } }
                                        data={article.content}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setArticle({
                                                ...article,
                                                content: data,
                                            })
                                        }}
                                    />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {
                                    isCreate
                                        ?
                                        <Box sx={{ paddingTop: '10px' }}>
                                            {loading ? <Box sx={{ color: '#1976d2', fontWeight: 'bold', lineHeight: '36px', display: 'flex' }}>Đang tạo bài viết <LoaderEllipsis /></Box> : <Button variant="contained" component="span" onClick={handleCreateArticle}>Tạo bài viết</Button>}
                                        </Box>
                                        :
                                        <Box sx={{ paddingTop: '10px' }}>
                                            {loading ? <Box sx={{ color: '#1976d2', fontWeight: 'bold', lineHeight: '36px', display: 'flex' }}>Đang tạo bài viết <LoaderEllipsis /></Box> : <Button variant="contained" component="span" onClick={handleUpdateArticle}>Cập nhật bài viết</Button>}
                                            <Button sx={{marginLeft: '5px'}} variant="contained" color="error" component="span" onClick={handleDeleteArticle}>Xóa</Button>
                                        </Box>
                                }


                                <FieldBox>
                                    <Label>Thumbnail</Label>
                                    <ImageUploading
                                        multiple
                                        value={images}
                                        onChange={onChangeImageThumb}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }) => (
                                            <div className="upload__image-wrapper">
                                                {!imageList.length &&
                                                    <ButtonCustomUpload
                                                        style={isDragging ? { color: '#1f89e5' } : undefined}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        <InsertPhotoIcon />
                                                    </ButtonCustomUpload>
                                                }
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item">
                                                        <img src={image['data_url']} alt="" width="100" />
                                                        <div className="image-item__btn-wrapper">
                                                            <ButtonCustomUpload onClick={() => onImageUpdate(index)}>Sửa</ButtonCustomUpload>
                                                            <ButtonCustomUpload onClick={() => onImageRemove(index)}>Xóa</ButtonCustomUpload>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </FieldBox>
                                <FieldBox>
                                    <Label>{FieldArticle.shortDescription}</Label>
                                    <TextareaAutosize
                                        minRows={4}
                                        maxRows={4}
                                        style={{ width: "auto", padding: '16.5px 14px', borderColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '4px', outlineColor: '#1f89e5' }}
                                        value={article.shortDescription}
                                        name="shortDescription"
                                        onChange={handleInputChange}
                                    />
                                </FieldBox>
                                <FieldBox>
                                    <Label>{FieldArticle.categories}</Label>
                                    <Autocomplete
                                        multiple
                                        id="categories-filled"
                                        disableCloseOnSelect
                                        options={categories}
                                        getOptionLabel={(categories) => categories.name}
                                        value={article.categories}
                                        onChange={handleCategoriesChange}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Categories" />
                                        )}
                                    />
                                </FieldBox>
                                <FieldBox>
                                    <Label>{FieldArticle.tags}</Label>
                                    <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        disableCloseOnSelect
                                        options={tags}
                                        getOptionLabel={(tags) => tags.name}
                                        value={article.tags}
                                        onChange={handleTagsChange}
                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Tags" />
                                        )}
                                    />
                                </FieldBox>
                            </Grid>
                        </Grid>
                    </Box>
            }
        </>

    )
}

const FieldBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

const Label = styled(Box)`
  color: #58667E;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ButtonCustomUpload = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  color: #58667E;
  font-weight: bold;
  padding: 5px 10px;
  margin-right: 5px;
`;

const initialArticle = {
    title: '',
    thumbnail: '',
    shortDescription: '',
    categories: [],
    tags: [],
    content: '<p>Nội dung bài viết</p>',
    articleId: ""
};

export default ArticleDetail;