import axiosClient from "./axiosClient";

class PartnerApi {
  getList = () => {
    const url = '/partner/all';
    return axiosClient.get(url);
  };
  create = (params) => {
    const url = '/partner';
    return axiosClient.post(url, params);
  };
  edit = (params) => {
    const url = '/partner';
    return axiosClient.patch(url, params);
  };
  delete = (params) => {
    const url = '/partner';
    return axiosClient.delete(url, params);
  };
}

const partnerApi = new PartnerApi();
export default partnerApi;