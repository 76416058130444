import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import partnerApi from 'api/partnerApi';
import ImageUploading from 'react-images-uploading';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import styled from 'styled-components';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ createSuccess, open, handleClose, data, token, success, setSuccess, error, setError }) {
  const [logo, setLogo] = useState([]);
  const [partner, setPartner] = useState({
    name: '',
    logo: '',
    website: '',
  });

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(function (key) {
        if (key === 'name' || key === 'website' || key === 'logo') {
          setPartner(prevState => ({
                ...prevState,
                [key]: data[key]
            }))
        }
      });
      setLogo([{ data_url: data?.logo }])
    } else {
      setPartner({
        name: '',
        logo: '',
        website: '',
      })
      setLogo([])
    }
  }, [open]);

  const onChangeLogo = (imageList, addUpdateIndex) => {
    setLogo(imageList);
    setPartner({ ...partner, ['logo']: imageList[0].file });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPartner({ ...partner, [name]: value });
  }

  const handleCreate = () => {
    setError('');
    if (!partner.name || !partner.logo || !partner.website) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    } else {
      createPartner();
    }
  };

  const handleUpdate = () => {
    setError('');
    if (!partner.name || !partner.logo || !partner.website) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    } else {
      updatePartner();
    }
  }

  const updatePartner = async () => {
    try {
      const form = new FormData();
      form.append('name', partner.name);
      form.append('website', partner.website);
      form.append('logo', partner.logo);
      form.append('partnerId', data?.id);
      const response = await axios.patch(`${process.env.REACT_APP_URL_API}/partner`, form, { headers: { "Authorization": `Bearer ${token}` } });
      if (response) {
        setSuccess('Cập nhật đối tác thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  const createPartner = async () => {
    try {
      const form = new FormData();
      form.append('name', partner.name);
      form.append('website', partner.website);
      form.append('logo', partner.logo);
      const response = await partnerApi.create(form);
      if (response) {
        setSuccess('Tạo đối tác thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {!success && (
          <>
          <Box sx={{marginBottom: '15px', fontSize: '24px', fontWeight: '500'}}>
            
            { data ? 'Cập nhật đối tác' : 'Tạo đối tác mới' }
          </Box>
          <Box sx={{marginBottom: '10px'}}>
          <ImageUploading
              multiple
              value={logo}
              maxNumber={1}
              onChange={onChangeLogo}
              dataURLKey="data_url"
          >
              {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
              }) => (
                  <div className="upload__image-wrapper">
                      {!imageList.length &&
                          <ButtonCustomUpload
                              style={isDragging ? { color: '#1f89e5' } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                          >
                              <InsertPhotoIcon />
                          </ButtonCustomUpload>
                      }
                      {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                              <img src={image['data_url']} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                  <ButtonCustomUpload onClick={() => onImageUpdate(index)}>Sửa</ButtonCustomUpload>
                                  <ButtonCustomUpload onClick={() => onImageRemove(index)}>Xóa</ButtonCustomUpload>
                              </div>
                          </div>
                      ))}
                  </div>
              )}
          </ImageUploading>
          </Box>
          <Box sx={{marginBottom: '10px'}}>
          <TextField
            fullWidth
            required
            name="name"
            value={partner.name}
            onChange={handleInputChange}
            label="Tên đối tác"
            variant="outlined"
          />
          </Box>
          <Box sx={{marginBottom: '10px'}}>
          <TextField
            fullWidth
            required
            name="website"
            value={partner.website}
            onChange={handleInputChange}
            label="Website đối tác"
            variant="outlined"
          />
          </Box>
          {error && <Box sx={{marginTop: '15px'}}><Alert severity="error">{error}</Alert></Box>}
          <Box sx={{marginTop: '15px'}}>
            
            {
              data ?
                <Button variant="outlined" onClick={handleUpdate}>Cập nhật đối tác</Button>
              :
                <Button variant="outlined" onClick={handleCreate}>Tạo đối tác</Button>
            }
          </Box>
          </>
          )}
          {success && <Box sx={{marginTop: '15px'}}>
            <Alert severity="success">{success}</Alert>
            <Box sx={{marginTop: '15px'}}>
              <Button variant="outlined" onClick={handleClose}>Close</Button>
            </Box>
          </Box>}
        </Box>
      </Modal>
    </div>
  );
}

const Input = styled('input')({
  display: 'none',
});

const ButtonCustomUpload = styled.button`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  cursor: pointer;
  color: #58667E;
  font-weight: bold;
  padding: 5px 10px;
  margin-right: 5px;
`;