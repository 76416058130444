import axiosClient from "./axiosClient";

class AdminApi {
  getAll = (params) => {
    const url = '/admin/all';
    return axiosClient.get(url, { params });
  };
  getProfile = (params) => {
    const url = 'admin/me';
    return axiosClient.get(url, { params });
  }
}

const adminApi = new AdminApi();
export default adminApi;