import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import adminApi from 'api/adminApi';
import Modal from '@mui/material/Modal';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ createSuccess, open, handleClose, data, token, success, setSuccess, error, setError }) {
  const [admin, setAdmin] = React.useState({ email: '', name: '', password: ''});
  const [name, setName] = React.useState('');

  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setAdmin({
      ...admin,
      [name]: value
    });
  }

  useEffect(() => {
    if (data){
      setName(data.name)
    } else {
      setAdmin(prevState => {return {...prevState, email: ""}});
      setAdmin(prevState => {return {...prevState, name: ""}});
      setAdmin(prevState => {return {...prevState, password: ""}});
      setName('')
    }
  }, [open]);

  const handleCreate = () => {
    setError('');
    if (!admin.email || !admin.name || !admin.password) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    } else {
      createAdmin();
    }
  };

  const handleInputChangeName = (event) => {
    setName(event.target.value);
  }

  const handleUpdate = async () => {
    setError('');
    if (!name) {
      setError('Vui lòng nhập đầy đủ!');
      return;
    }
    try {
      const params = {
        adminId: data.adminId,
        name: name
      }
      const response = await axios.patch(`${process.env.REACT_APP_URL_API}/admin`, params, { headers: { "Authorization": `Bearer ${token}` } });
      if (response) {
        setSuccess('Thay đổi thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }

  const createAdmin = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL_API}/admin`, admin, { headers: { "Authorization": `Bearer ${token}` } });
      if (response) {
        setSuccess('Tạo admin thành công!');
        createSuccess();
      }
    } catch (e) {
      setError(e.message);
    }
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {!success && (
          <>
          <Box sx={{marginBottom: '15px', fontSize: '24px', fontWeight: '500'}}>
          { data ? `Thay đổi tên ${data.name}` : 'Tạo admin mới' }
          </Box>
          {!data && (
            <>
              <Box sx={{marginBottom: '15px'}}>
                <TextField
                  fullWidth
                  required
                  name="email"
                  value={admin.email}
                  onChange={handleInputChange}
                  label="Email"
                  variant="outlined"
                />
              </Box>
              <Box sx={{marginBottom: '15px'}}>
                <TextField
                  fullWidth
                  required
                  name="name"
                  value={admin.name}
                  onChange={handleInputChange}
                  label="Name"
                  variant="outlined"
                />
              </Box>
              <Box sx={{marginBottom: '15px'}}>
                <TextField
                  fullWidth
                  required
                  name="password"
                  value={admin.password}
                  onChange={handleInputChange}
                  label="Password"
                  variant="outlined"
                />
              </Box>
            </>
          )}
          {data && (
            <Box sx={{marginBottom: '15px'}}>
              <TextField
                fullWidth
                required
                name="nameEdit"
                value={name}
                onChange={handleInputChangeName}
                label="Tên mới"
                variant="outlined"
              />
            </Box>
          )}
          {error && <Box sx={{marginTop: '15px'}}><Alert severity="error">{error}</Alert></Box>}
          <Box sx={{marginTop: '15px'}}>
            {
              data ?
                <Button variant="outlined" onClick={handleUpdate}>Cập nhật admin</Button>
              :
                <Button variant="outlined" onClick={handleCreate}>Tạo admin mới</Button>
            }
          </Box>
          </>
          )}
          {success && <Box sx={{marginTop: '15px'}}>
            <Alert severity="success">{success}</Alert>
            <Box sx={{marginTop: '15px'}}>
              <Button variant="outlined" onClick={handleClose}>Close</Button>
            </Box>
          </Box>}
        </Box>
      </Modal>
    </div>
  );
}