import React, { useEffect, useState } from "react";
import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import PageTitle from '../../components/display/PageTitle'
import TableSection from './components/Table/TableSection';
import Loading from '../../components/display/Loading'
import adminApi from '../../api/adminApi';

function ListAdmin(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const res = await adminApi.getAll();
            if (res.data) {
                const items = res.data;
                setData(items);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Box>
            <PageTitle text={'Danh sách admin'} />
            {
              loading ?
                  <Loading />
                  :
                  <>
                      <Col>
                          <TableSection data={data} />
                      </Col>
                  </>
            }

        </Box>
    );

}

const Col = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-left: 24px;
    margin-right: 24px;
`

export default ListAdmin;